/* @flow */

import type { Mode } from "state/view-mode";
import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { Menu } from "@crossroads/ui-components";
import cn from "classnames";
import useFormat from "helpers/use-format";
import SystemMessages from "components/SystemMessages";
import Logo from "components/AppHeader/Logo";
import CustomerBadge from "components/CustomerBadge";
import { MODE } from "state/view-mode";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import SubMenu, { useHideSubMenu } from "components/AppHeader/Large/SubMenu";
import HamburgerIcon from "icons/hamburger.svg";
import ServiceIcon from "static/vectors/icon-customer-service.svg";
import AdminButton from "components/AccountView/AdminButton";

import styles from "./styles.scss";

type Props = {
  className: string,
  mode: Mode,
  setMode: Mode => void,
  onHomeView: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  onHeroRoute: boolean,
  lastVisited: ?string,
  customer: ?Customer,
  logoWidth: string,
  isScrolled: boolean,
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleString("default", { month: "long", day: "numeric" });
};

const noDimModes = [MODE.NORMAL, MODE.RECRUIT, MODE.TINK];

const AppHeaderLarge = ({ className = "", mode, setMode, onHomeView, onHeroRoute, onCheckout, lastVisited, customer, logoWidth, onSuccess, isScrolled }: Props) => {
  const t = useTranslate();
  const storeInfo = useContext(StoreInfoContext);
  const { location: { pathname } } = useHistory();
  const { routes } = storeInfo;
  const { configuration: { helpdeskTitle, helpdeskLink } } = storeInfo;
  const {
    info: { code, awardit: { partneruserid } },
    content: { accountview, tinkview },
  } = storeInfo;
  const { content: { heroIntroView, appheader: { compactMenu } } } = storeInfo;
  const { formatPoints } = useFormat();
  const hideSubMenu = useHideSubMenu();
  const showAdminButton = accountview.showAdministration &&
    hideSubMenu &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;

  const isLogoInverted = (isHeroActivated: boolean) => {
    if (isHeroActivated === false) {
      return true;
    }

    if (!onHeroRoute && routes.earnView.url !== pathname) {
      return false;
    }

    if (isScrolled && (onHeroRoute || routes.earnView.url === pathname)) {
      return false;
    }

    return true;
  };

  let UPDATEDROUTES = null;
  if (tinkview.hideInNavbar) {
    UPDATEDROUTES = ROUTES.filter(item => item !== "accountTinkView");
  }

  const nav = navRoutes(UPDATEDROUTES ? UPDATEDROUTES : ROUTES, storeInfo);

  const expiringPoints = customer && customer.awardit.expiringPoints > 0 ?
    formatPoints(customer.awardit.expiringPoints) : null;
  const expiringPointsDate = customer && customer.awardit.expiringPointsDate ?
    formatDate(customer.awardit.expiringPointsDate) : null;

  return (
    <div className={cn("awardit-appHeaderContainerLarge", styles.container)}>
      <div className={cn(styles.block, className, { [styles.fullMenu]: onHomeView })}>
        <Link className={cn(styles.item, styles.logo)} to={routes.homeView.url}>
          <Logo
            className="awardit-headerLogo"
            width={logoWidth}
            isInverted={onHomeView ? isLogoInverted(heroIntroView.toggle) : !isScrolled}
            onClick={() => setMode(MODE.NORMAL)}
          />
        </Link>

        <nav className={cn("awardit-appHeaderContainerLargeNav", styles.nav)}>
          {!onCheckout && !compactMenu &&
            <>
              {helpdeskLink && helpdeskTitle &&
              (code === "ahlsell_sek" || (code === "ahlsell_sek" && partneruserid === 5443)) &&
                <a
                  href={helpdeskLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cn(styles.item, styles.helpdesk)}
                  title={helpdeskTitle}
                >
                  <ServiceIcon /><span>{helpdeskTitle}</span>
                </a>
              }
              {nav.map(x => (
                <NavLink
                  key={x.url}
                  to={x.url}
                  activeClassName={styles.active}
                  className={styles.item}
                >
                  {x.title}
                </NavLink>
              ))}
            </>
          }

          {compactMenu &&
            <Menu className={styles.item} icon={<HamburgerIcon />}>
              {!onCheckout && nav.map(x => (
                <NavLink key={x.url} to={x.url} className={styles.menuItem}>
                  {x.title}
                </NavLink>
              ))}
              {!onCheckout && routes.suppliersView && routes.suppliersView.toggle && (
                <NavLink to={routes.suppliersView.url} className={styles.menuItem}>
                  {routes.suppliersView.title}
                </NavLink>
              )}
            </Menu>
          }

          {expiringPointsDate && expiringPoints &&
            <NavLink
              to={routes.accountView.url}
              className={cn(styles.item, styles.expiresNotice)}
            >
              <div className={styles.exclamation}>!</div>
              <div className={styles.notice}>
                <span className={styles.date}>
                  {`${t("ACCOUNT.EXPIRES")} ${expiringPointsDate}`}
                </span>
                <span className={styles.points}>
                  {expiringPoints}
                </span>
              </div>
            </NavLink>
          }

          {routes.accountView && routes.accountView.toggle && (
            <NavLink
              to={routes.accountView.url}
              activeClassName={styles.active}
              className={cn(styles.item, styles.item__customerBadge)}
            >
              <CustomerBadge customer={customer} />
            </NavLink>
          )}

          {showAdminButton &&
            <div className={cn(styles.adminBtnContainer, "awardit-adminButton")}>
              <AdminButton text={accountview.administration} />
            </div>
          }
        </nav>
      </div>

      <SubMenu
        mode={mode}
        setMode={setMode}
        lastVisited={lastVisited}
        onCheckout={onCheckout}
        onSuccess={onSuccess} />

      {!noDimModes.includes(mode) &&
        <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
      }

      <div>
        <SystemMessages />
      </div>
    </div>
  );
};

export default AppHeaderLarge;
