/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import useFormat from "helpers/use-format";
import { getCustomerActiveBalance } from "helpers/points";
import UserIcon from "icons/user.svg";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  className?: string,
  customer: ?Customer,
};

const CustomerBadge = ({ customer, className }: Props) => {
  const { formatPoints } = useFormat();
  const { content: { appheader } } = useContext(StoreInfoContext);

  const slabImage = (
    customer &&
    customer.memberPoints &&
    customer.memberPoints.slabImage) ? customer.memberPoints.slabImage : null;

  if (!customer) {
    return null;
  }

  const customerBalance = getCustomerActiveBalance(customer);
  const formattedBalance = formatPoints(customerBalance);

  return (
    <div className={cn(styles.block, className)}>
      {customer && customer.memberPoints && customer.memberPoints.slabImage ?
        <div className={cn((appheader.slabImageBackground ? styles.slabBackground : styles.slabLarge), "awardit-headerMemberBadge")}>
          <img src={slabImage} />
        </div> : <UserIcon />}
      {customer &&
      <div className={styles.customerInfo}>
        <p className="awardit-headerMemberSlabName"><strong>{customer.firstname} {customer.lastname}</strong> {customer.memberPoints && customer.memberPoints.slab && `|  ${customer.memberPoints.slab}`}</p>
        <p>{formattedBalance}</p>
      </div>
      }
    </div>
  );
};

export default CustomerBadge;
