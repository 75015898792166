/* @flow */

import type { Customer } from "shop-state/types";

import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";
import { Form, rules, isRequired, isNumeric } from "@awardit/formaggio";
import { useData, useSendMessage } from "crustate/react";
import { ConnectedAccountsData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { transferPoints } from "state/connected-accounts";
import Wrapper from "components/Wrapper";
import Field from "components/Field";
import Button from "components/Button";
import { BoxBody, Box } from "components/Box";
import Back from "components/AccountView/Back";
import Header from "components/AccountView/Header";
import useFormat from "helpers/use-format";
import { numberGt, numberLt } from "helpers/validation";
import YourPoints from "components/AccountView/your-points";

import styles from "./styles.scss";

type PointsForm = {
  fromMember: string,
  toMember: string,
  points: number,
};

const PointsView = ({ customer }: { customer: Customer }) => {
  const sendMessage = useSendMessage();
  const { content: { pointsview } } = useContext(StoreInfoContext);
  const connectedAccounts = useData(ConnectedAccountsData);
  const accounts = connectedAccounts.data;
  const loading = connectedAccounts.state === "TRANSFERING";
  const defaultState = {
    fromMember: accounts?.from && accounts.from.length > 0 ? accounts.from[0].email : "",
    toMember: accounts?.to && accounts.to.length > 0 ? accounts.to[0].email : "",
    points: 0,
  };
  const [state, setState] = useState<PointsForm>(defaultState);
  const fromMember = accounts?.from.find(a => a.email === state.fromMember) || {};
  const { formatPoints } = useFormat(false);

  const validation = rules([
    isRequired("fromMember"),
    isRequired("toMember"),
    isRequired("points"),
    isNumeric("points"),
    numberGt("points", 0),
    numberLt("points", fromMember.points + 1 || 0),
  ]);

  const errors = validation((state: any));

  const submit = async e => {
    e.preventDefault();

    if (loading) {
      return;
    }

    await sendMessage(transferPoints(parseInt(state.points, 10), state.fromMember, state.toMember));
  };

  const showTransfer = Boolean(accounts && (accounts.to.length > 1 || accounts.from.length > 1));

  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper awardit-pointsView">
      <Helmet title={pointsview.pageTitle} />
      <Back />
      <Header subHeader={pointsview.header} />

      <div className={styles.container}>
        <Box className={styles.left}>
          <YourPoints customer={customer} />
        </Box>

        {showTransfer &&
          <Box className={styles.right}>
            <header className={styles.boxHeader}>
              <h2>{pointsview.transfer}</h2>
              <p>{pointsview.transferDescription}</p>
            </header>
            <Form
              disabled={loading}
              errors={errors}
              value={(state: any)}
              onChange={x => {
                setState({ ...state, ...(x: any) });
              }}
              onSubmit={submit}
            >
              <BoxBody className={styles.removeBottomPadding}>
                <div className={styles.row}>
                  {accounts && accounts.from && accounts.from.length > 0 &&
                    <div className={styles.s50}>
                      <label htmlFor="fromMember" className={styles.label}>{pointsview.from}</label>
                      <Dropdown
                        className={styles.selector}
                        name="fromMember"
                        value={state.fromMember}
                        placeholder={pointsview.selectAccount}
                        onChange={(fromMember: any) => {
                          setState({ ...state, fromMember });
                        }}
                      >
                        {accounts.from.map(a => (
                          <DropdownItem key={`from_${a.email}`} value={a.email}>
                            {`${a.email} - ${pointsview.amount} \n ${formatPoints(a.points)}`}
                          </DropdownItem>
                        ))}
                      </Dropdown>
                    </div>
                  }
                  {accounts && accounts.to && accounts.to.length > 0 &&
                    <div className={styles.s50}>
                      <label htmlFor="toMember" className={styles.label}>{pointsview.to}</label>
                      <Dropdown
                        className={styles.selector}
                        name="toMember"
                        value={state.toMember}
                        placeholder={pointsview.selectAccount}
                        onChange={(toMember: any) => {
                          setState({ ...state, toMember });
                        }}
                      >
                        {accounts.to.map(a => (
                          <DropdownItem key={`from_${a.email}`} value={a.email}>
                            {`${a.email} - ${pointsview.amount} \n ${formatPoints(a.points)}`}
                          </DropdownItem>
                        ))}
                      </Dropdown>
                    </div>
                  }
                </div>
                <div className={styles.row}>
                  <div className={styles.s50}>
                    <Field name="points" label={pointsview.pointsAmount} />
                  </div>
                  <div className={styles.s50}>
                    <div className={styles.spacer} />
                    <Button
                      type="submit"
                      variant="primary"
                      loading={loading}
                      className={styles.button}
                    >
                      <span>{pointsview.transferButton}</span>
                    </Button>
                  </div>
                </div>
              </BoxBody>
            </Form>
          </Box>
        }
      </div>
    </Wrapper>
  );
};

export default PointsView;
