/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import styles from "./styles.scss";

type Props = {
  className?: string,
  width: string,
  isInverted?: boolean,
  loginViewLogo?: string,
};

export const logoSvg = (logo: string, width: string) => {
  return (
    <img src={logo} width={width} />
  );
};

export const SecondaryLogo = ({ width = "135px", className, ...props }: Props) => {
  const {
    configuration: { logoSecondary },
  } = useContext(StoreInfoContext);

  return (
    <div {...props} className={cn(styles.block, className)}>
      {logoSecondary && logoSvg(logoSecondary, width) }
    </div>
  );
};

const Logo = ({ width = "135px", isInverted, loginViewLogo, className, ...props }: Props) => {
  const {
    info: { name },
    configuration: { logo, logoInverted },
  } = useContext(StoreInfoContext);

  const logoType = loginViewLogo ? loginViewLogo : logo;

  return (
    <div {...props} className={cn(styles.block, className)}>
      {logoInverted && isInverted !== undefined && isInverted ?
        logoSvg(logoInverted, width) : logoSvg(logoType, width) }
      {!logo && name &&
        <span className={styles.name}>
          {name}
        </span>
      }
    </div>
  );
};

export default Logo;
