/* @flow */

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useData } from "crustate/react";
import { CustomerData } from "data";
import Button from "components/Button";
import CustomerBadge from "components/CustomerBadge";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";
import cn from "classnames";

const HomeHero = () => {
  const {
    content: {
      homeview: content,
    },
    routes,
  } = useContext(StoreInfoContext);
  const customerData = useData(CustomerData);
  const customer = customerData.state === "LOGGED_IN" ||
    customerData.state === "UPDATING" ? customerData.data : null;

  return (
    <div
      className={cn("awardit-homeHero", styles.hero)}
    >
      <picture className={styles.image}>
        <source
          srcSet={content.heroImageSmall}
          media={`(max-width: ${parseInt(styles.tiny, 10) - 1}px)`}
        />
        <source
          srcSet={content.heroImageLarge}
          media={`(min-width: ${parseInt(styles.tiny, 10)}px)`}
        />
        <img
          alt=""
          src={content.heroImageLarge}
        />
      </picture>
      <NavLink
        to={routes.accountView.url}
        className={styles.customer_badge}
      >
        <CustomerBadge
          customer={customer}
        />
      </NavLink>
      <div className={cn("awardit-homeHeroContent", styles.content)}>
        {content.heroIcon && (
          <div className={styles.heroIcon}>
            <img
              alt=""
              src={content.heroIcon}
            />
          </div>
        )}
        <h1>{content.heroHeading}</h1>
        {content.heroDescription &&
          <>
            {/* eslint-disable react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: content.heroDescription }} />
            {/* eslint-enable react/no-danger */}
          </>
        }
        {content.heroButtonLink &&
          <Button
            to={content.heroButtonLink}
            variant="primary"
            size="medium"
          >
            {content.heroButtonText}
          </Button>
        }
      </div>
    </div>
  );
};

export default HomeHero;
