/* @flow */

import React, { useContext, useRef } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { CustomerData } from "data";
import { focusInvalidField } from "helpers/utils";
import { useData, useSendMessage } from "crustate/react";
import { login } from "@crossroads/shop-state/customer";
import { StoreInfoContext } from "entrypoint/shared";
import styles from "./styles.scss";

type Props = {
  className?: string,
};

const LoginForm = ({ className }: Props) => {
  const t = useTranslate();
  const { routes, content: { loginview } } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const sendMessage = useSendMessage();
  const loading = customer.state === "LOGGING_IN";

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (usernameRef.current && usernameRef.current.value &&
      passwordRef.current && passwordRef.current.value) {
      sendMessage(login(usernameRef.current.value, passwordRef.current.value));
    }
  };

  return (
    <div
      className={cn(styles.block, className, "awardit-loginForm")}
      style={loginview.formOpacity ? { backgroundColor: `rgba(255, 255, 255, ${loginview.formOpacity})` } : {}}
    >
      <div>
        <h2>{t("LOGIN.TITLE")}</h2>
        <form
          method="POST"
          onError={focusInvalidField}
          onSubmit={submit}
        >
          <div className={styles.input}>
            <div className={styles.inputBlock}>
              <label htmlFor="username" className={styles.inputLabelElement}>
                {loginview.usernameText}
              </label>
              <input ref={usernameRef} required id="username" className={styles.inputElement} type="text" name="username" />
            </div>
          </div>

          <div className={styles.input}>
            <div className={styles.inputBlock}>
              <label htmlFor="password" className={styles.inputLabelElement}>
                {loginview.userPasswordText}
              </label>
              <input ref={passwordRef} required autoComplete="off" id="password" className={styles.inputElement} type="password" name="password" />
            </div>
          </div>

          <Button
            id="login"
            type="submit"
            className={styles.button}
            loading={loading}
            variant="primary"
          >
            {t("LOGIN.SUBMIT")}
          </Button>
          <noscript>
            <p className={styles.noScriptParagraph}>
              {t("NO_JAVASCRIPT.LOGIN")}
            </p>
          </noscript>
        </form>
      </div>
      <div className={styles.bottom}>
        {routes.passwordResetView.url && (
          <div className={cn(styles.forgot_password, "awardit-loginFormForgotPassword")}>
            <p>{loginview.forgotPasswordButtonHeading}</p>
            <Link
              to={routes.passwordResetView.url}
            >
              {loginview.forgotPasswordButtonText}
            </Link>
          </div>
        )}

        {loginview.createAccountEnabled &&
          <div className={cn(styles.no_account, "awardit-loginFormNoAccount")}>
            <Link to={routes.registerView.url}>{loginview.createAccountButtonText ?? t("LOGIN.CREATE_ACCOUNT")}</Link>
          </div>
        }

      </div>
    </div>
  );
};

export default LoginForm;
